<template>
  <div>
    <a-modal
      width="1200px"
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="新增通用套餐"
      @ok="handleSubmit"
    >
      <a-form
        class="simiot-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-row>
          <!--基本信息， 周期容量设置-->
          <a-col :span="12">
            <span class="item-title">基本信息</span>
            <a-form-item label="通用套餐名称">
              <a-input
                v-decorator="['name', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { required: true, message: '请输入通用套餐名称' },
                    { max: 100, message: '最多100个字符' },
                  ]
                }]"
              />
            </a-form-item>

            <a-form-item :label="`运营商种类`">
              <a-select
                v-decorator="['carrier_type', {
                  rules: [
                    { required: true, message: '请选择运营商种类' }
                  ]
                }]"
                @change="handleCarrierTypeChange"
              >
                <a-select-option
                  v-for="option in carrierTypeOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.label }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item :label="`关联运营商`">
              <a-select
                mode="multiple"
                placeholder="允许零售或自订时必选"
                v-decorator="['carrier_account_ids', {
                  rules: [{ required: isAllowRetail === true || isAllowSelfSubscribe === true, message: '请选择运营商账户' }]
                }]"
                allow-clear
              >
                <a-select-option
                  v-for="option in carrierAccountOptions"
                  :key="option.id"
                  :value="option.id"
                >
                  {{ option.name }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item :label="`流量属性`">
              <a-select
                v-decorator="['traffic_property',
                              { initialValue: '',
                                rules: [{ required: true, message: '请选择流量属性' }]
                              },
                ]"
                allow-clear
              >
                <a-select-option value="general">
                  通用
                </a-select-option>
                <a-select-option value="directional">
                  定向
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item :label="`用户业务模式`">
              <a-select
                @change="handleUserBusinessTypeChange"
                v-decorator="['user_business_type', {
                  initialValue: 'custom_month',
                  rules: [{ required: true, message: '请选择用户业务模式' }]
                }]"
              >
                <a-select-option
                  v-for="option in userBusinessTypeOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.name }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item label="套餐描述">
              <a-textarea
                v-decorator="['remark', {
                  rules: [
                    { max: 200, message: '最多200个字符' },
                  ]
                }]"
              />
            </a-form-item>

            <span class="item-title">周期与容量</span>

            <a-form-item label="套餐周期" v-if="userBusinessType == 'traffic_share'">
              <span>1个自然月</span>
            </a-form-item>

            <a-form-item label="套餐周期" v-else-if="userBusinessType == 'traffic_share_package'">
              <span>无限周期</span>
            </a-form-item>

            <a-form-item label="套餐周期" v-else-if="userBusinessType == 'merchant_sales'">
              <span>无限周期</span>
            </a-form-item>

            <a-form-item
              label="套餐周期"
              required="required"
              v-else
              style="margin-bottom: 0px"
            >
              <a-input-group compact>
                <a-form-item
                  :style="{ display: 'inline-block', width: '100%'}"
                >
                  <a-space>
                    <a-input-number
                      :min="1"
                      :max="10000000"
                      style="width: 280px;"
                      v-decorator="['service_cycle', {
                        rules: [
                          { required: true, message: '请输入套餐周期' },
                          { type: 'integer', message: '请输入 1 - 10000000 之间的整数' }
                        ]
                      }]"
                    />
                    <span style="margin-left: 5px" v-if="userBusinessType == 'custom_month'">个自然月</span>
                    <span style="margin-left: 5px" v-else>天</span>
                  </a-space>
                </a-form-item>
              </a-input-group>
            </a-form-item>

            <a-form-item
              label="套餐容量"
              required="required"
              style="margin-bottom: 0px"
            >
              <a-input-group compact>
                <a-form-item
                  :style="{ display: 'inline-block', width: '80%' }"
                >
                  <a-space>
                    <a-input-number
                      :min="0.00"
                      :max="10000000"
                      style="width: 280px;"
                      v-decorator="[ 'package_capacity', {
                        rules: [
                          { required: true, message: '请输入套餐容量' },
                          packageCapacityTypeRule
                        ],
                      }]"
                    />
                  </a-space>
                </a-form-item>

                <a-form-item :style="{ display: 'inline-block', width: '20%' }">
                  <a-select
                    @change="handleCapacityUnitChange"
                    v-decorator="['capacity_unit', { initialValue: 'mb' }]"
                    style="width: 65px"
                  >
                    <a-select-option
                      v-for="option in capacityUnitOptions"
                      :key="option.value"
                      :value="option.value"
                    >
                      {{ option.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-input-group>
            </a-form-item>

            <a-form-item label="语音功能">
              <a-input-group compact>
                <a-form-item :style="{ display: 'inline-block', width: '15%', marginBottom: 0 }">
                  <a-checkbox v-decorator="['is_has_voice_function', { valuePropName: 'checked' }]" />
                </a-form-item>

                <a-form-item
                  :style="{ display: 'inline-block', width: '85%', marginBottom: 0 }"
                  v-if="isHasVoiceFunction"
                >
                  <a-space>
                    <a-input-number
                      style="width: 226px"
                      :min="1"
                      :max="999999"
                      :step="1"
                      :precision="0"
                      v-decorator="['voice_capacity', {
                        rules: [
                          { required: isHasVoiceFunction, message: '请输入语音时长' }
                        ]
                      }]"
                    />
                    <span style="margin-left: 5px">分钟</span>
                  </a-space>
                </a-form-item>
              </a-input-group>
            </a-form-item>

            <div v-show="userBusinessType === 'custom_month' || userBusinessType === 'traffic_share'">
              <span class="item-title">零售设置</span>

              <a-form-item label="允许零售">
                <a-checkbox
                  v-decorator="['is_allow_retail', { valuePropName: 'checked' }]"
                />允许通过小程序订购
              </a-form-item>

              <a-form-item label="零售价设置者" v-show="isAllowRetail">
                <a-radio-group
                  v-decorator="['retail_operator', {
                    initialValue: 'user',
                    rules: [
                      { required: isAllowRetail, message: '请选择零售价设置者' },
                    ]
                  }]"
                >
                  <a-radio value="platform">
                    平台
                  </a-radio>
                  <a-radio value="user">
                    零售商
                  </a-radio>
                </a-radio-group>
              </a-form-item>
            </div>

            <div v-show="userBusinessType === 'custom_month' || userBusinessType === 'traffic_share'">
              <span class="item-title">其它设置</span>

              <a-form-item label="允许自订">
                <a-checkbox
                  v-decorator="['is_allow_self_subscribe', { valuePropName: 'checked' }]"
                /> 允许通过CMP订购
              </a-form-item>
            </div>
          </a-col>

          <!--价格设置-->
          <a-col :span="12">
            <span class="item-title">价格设置</span>
            <div class="simiot-table-operation-buttons" v-show="true">
              <a-space>
                <a-button type="primary" @click="addSetPriceItem"> 新增</a-button>
              </a-space>
            </div>

            <a-row style="margin-top: 10px; margin-bottom: 10px" :gutter="24">
              <a-col :span="favorablePriceSpanSize">
                周期数*
              </a-col>
              <a-col :span="favorablePriceSpanSize">
                标准价(元)*
              </a-col>
              <a-col :span="favorablePriceSpanSize">
                底价(元)*
              </a-col>
              <a-col :span="favorablePriceSpanSize" v-if="isAllowSetRetailPrice">
                零售价(元)
              </a-col>
            </a-row>

            <a-row
              :gutter="24"
              v-for="(k) in form.getFieldValue('keys')"
              :key="k"
            >
              <a-col :span="favorablePriceSpanSize">
                <a-form-item>
                  <a-input-number
                    :min="1"
                    :max="10000000"
                    style="width: 120px"
                    @change="validateFavorablePriceCycles"
                    v-decorator="[`base_product_favorable_prices[${k}].cycles`, {
                      initialValue: k === 0 ? 1 : undefined,
                      rules: [
                        { required: true, message: '请输入周期数' },
                        { type: 'integer', message: '请输入 1 - 10000000 之间的整数' },
                        { validator: (rule, value, callback) => checkCycles(rule, value, callback, k) }
                      ]
                    }]"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="favorablePriceSpanSize">
                <a-form-item>
                  <a-input-number
                    :min="0"
                    :max="10000000"
                    :step="0.01"
                    :precision="2"
                    style="width: 120px"
                    v-decorator="[`base_product_favorable_prices[${k}].distributor_price`, {
                      rules: [
                        { required: true, message: '请输入标准价' },
                        { type: 'number', message: '请输入 0 - 10000000 之间的数字' }
                      ]
                    }]"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="favorablePriceSpanSize">
                <a-input-group compact>
                  <a-form-item>
                    <a-space>
                      <a-input-number
                        :min="0"
                        :max="10000000"
                        :step="0.01"
                        :precision="2"
                        style="width: 120px"
                        v-decorator="[`base_product_favorable_prices[${k}].floor_price`, {
                          rules: [
                            { required: true, message: '请输入底价' },
                            { type: 'number', message: '请输入 0 - 10000000 之间的数字' }
                          ]
                        }]"
                      />

                      <a-icon
                        :style="{ display: 'inline-block'}"
                        class="dynamic-delete-button"
                        type="minus-circle-o"
                        v-show="form.getFieldValue('keys').length > 1 && !isAllowSetRetailPrice"
                        :disabled="form.getFieldValue('keys').length === 1"
                        @click="() => removeSetPriceItem(k)"
                      />
                    </a-space>
                  </a-form-item>
                </a-input-group>
              </a-col>

              <a-col :span="favorablePriceSpanSize" v-if="isAllowSetRetailPrice">
                <a-input-group compact>
                  <a-form-item>
                    <a-space>
                      <a-input-number
                        :min="0"
                        :max="10000000"
                        :step="0.01"
                        :precision="2"
                        style="width: 120px"
                        v-decorator="[`base_product_favorable_prices[${k}].retail_price`, {
                          rules: [
                            { type: 'number', message: '请输入 0 - 10000000 之间的数字' },
                            { validator: (rule, value, callback) => checkRetailPrice(rule, value, callback, k) }
                          ]
                        }]"
                      />

                      <a-icon
                        :style="{ display: 'inline-block'}"
                        class="dynamic-delete-button"
                        type="minus-circle-o"
                        v-show="form.getFieldValue('keys').length > 1 && isAllowSetRetailPrice"
                        :disabled="form.getFieldValue('keys').length === 1"
                        @click="() => removeSetPriceItem(k)"
                      />
                    </a-space>
                  </a-form-item>
                </a-input-group>
              </a-col>
            </a-row>

            <a-row style="margin-top: 10px" :gutter="24" v-show="isShowExcessRateItem">
              <a-col :span="8">
                超额计费单位*
              </a-col>
              <a-col :span="8">
                超额标准价(元)*
              </a-col>
              <a-col :span="8">
                超额底价(元)*
              </a-col>
            </a-row>

            <a-row style="margin-top: 10px" :gutter="24" v-show="isShowExcessRateItem">
              <a-col :span="8">
                <a-input-group compact>
                  <a-form-item>
                    <a-space>
                      <a-input-number
                        style="width: 120px"
                        :min="1"
                        :max="10000000"
                        v-decorator="['excess_rate_quantity', {
                          rules: [
                            { required: isShowExcessRateItem, message: '请输入超额计费单位' },
                            { type: 'integer', message: '请输入 1 - 10000000 之间的整数' }
                          ]
                        }]"
                      />
                      <span>{{ excessRateQuantityUnit }}</span>
                    </a-space>
                  </a-form-item>
                </a-input-group>
              </a-col>

              <a-col :span="8">
                <a-form-item>
                  <a-input-number
                    :min="0"
                    :max="10000000"
                    :step="0.01"
                    :precision="2"
                    style="width: 120px;"
                    v-decorator="['excess_distributor_price', {
                      rules: [
                        { required: isShowExcessRateItem, message: '请输入超额标准价' },
                        { type: 'number', message: '请输入 0 - 10000000 之间的数字' }
                      ]
                    }]"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item>
                  <a-input-number
                    :min="0"
                    :max="10000000"
                    :step="0.01"
                    :precision="2"
                    style="width: 120px"
                    v-decorator="['excess_floor_price', {
                      rules: [
                        { required: isShowExcessRateItem, message: '请输入超额底价' },
                        { type: 'number', message: '请输入 0 - 10000000 之间的数字' }
                      ]
                    }]"
                  />
                </a-form-item>
              </a-col>
            </a-row>

            <a-row style="margin-top: 10px" :gutter="24" v-show="isShowExcessRateItem">
              <a-col :span="8">
                停机保号标准价(元)*
              </a-col>
              <a-col :span="8">
                停机保号底价(元)*
              </a-col>
            </a-row>

            <a-row style="margin-top: 10px" :gutter="24" v-show="isShowStopItem">
              <a-col :span="8">
                <a-form-item>
                  <a-input-number
                    :min="0"
                    :max="10000000"
                    :step="0.01"
                    :precision="2"
                    style="width: 120px;"
                    v-decorator="['stop_distributor_price', {
                      rules: [
                        { required: isShowStopItem, message: '请输入停机保号标准价' },
                        { type: 'number', message: '请输入 0 - 10000000 之间的数字' }
                      ]
                    }]"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item>
                  <a-input-number
                    :min="0"
                    :max="10000000"
                    :step="0.01"
                    :precision="2"
                    style="width: 120px"
                    v-decorator="['stop_floor_price', {
                      rules: [
                        { required: isShowStopItem, message: '请输入停机保号底价' },
                        { type: 'number', message: '请输入 0 - 10000000 之间的数字' }
                      ]
                    }]"
                  />
                </a-form-item>
              </a-col>
            </a-row>

            <span class="item-title">卡片设置</span>
            <a-row style="margin: 10px 0 10px 9px" :gutter="24">
              <a-col :span="8">
                卡片材质*
              </a-col>
              <a-col :span="8">
                标准价(元)*
              </a-col>
              <a-col :span="8">
                底价(元)*
              </a-col>
            </a-row>

            <a-row
              :gutter="24"
              v-for="(cardType, index) in cardTypes"
              :key="'baseProductCardType-'+ cardType.id"
            >
              <a-col :span="8">
                <a-form-item>
                  <a-row style="width: 150px">
                    <a-col :span="4">
                      <a-checkbox-group
                        v-decorator="[`base_products_card_types[${index}].card_type_id`]"
                        @change="(checked) => changeCardType(checked, index)"
                        style="width: 155px"
                      >
                        <a-checkbox :value="cardType.id" />
                      </a-checkbox-group>
                    </a-col>
                    <a-col :span="20">
                      {{ cardType.name }}
                    </a-col>
                  </a-row>
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item>
                  <a-input-number
                    :min="0"
                    :max="10000000"
                    :step="0.01"
                    :precision="2"
                    style="width: 120px"
                    v-decorator="[`base_products_card_types[${index}].distributor_price`, {
                      rules: [
                        { required: cardTypePriceRequired[index], message: '请输入标准价' },
                        { type: 'number', message: '请输入 0 - 10000000 之间的数字' }
                      ]
                    }]"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item>
                  <a-input-number
                    :min="0"
                    :max="10000000"
                    :step="0.01"
                    :precision="2"
                    style="width: 120px"
                    v-decorator="[`base_products_card_types[${index}].floor_price`, {
                      rules: [
                        { required: cardTypePriceRequired[index], message: '请输入底价' },
                        { type: 'number', message: '请输入 0 - 10000000 之间的数字' }
                      ]
                    }]"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { findCarrierTypeOptions, findUserBusinessTypeOptions, findCarrierAccountOptions, findCardTypeOptions } from '@/api/common_options'
import { createBaseProduct } from '@/api/base_product'

export default {
  name: 'NewBaseProduct',
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'base_product', onValuesChange: this.onValuesChange }),
      submitting: false,
      carrierTypeOptions: findCarrierTypeOptions(),
      userBusinessTypeOptions: findUserBusinessTypeOptions(),
      capacityUnitOptions: [
        { name: 'MB', value: 'mb' },
        { name: 'GB', value: 'gb' },
        { name: '次', value: 'count' }
      ],
      excessRateQuantityUnit: 'MB',
      isShowExcessRateItem: true, // 是否显示设置超额参数一项
      isShowStopItem: true, // 是否显示设置停机保号参数一项
      userBusinessType: 'custom_month',
      packageCapacityTypeRule: {
        type: 'number',
        message: '请输入 0.01 - 10000000 之间的数字'
      },
      cardTypes: [], // 卡片材质
      cardTypePriceRequired: [],
      carrierAccountOptions: [],
      priceIndex: 1,
      isHasVoiceFunction: false,
      isAllowRetail: false,
      isAllowSetRetailPrice: false,
      isAllowSelfSubscribe: false,
      favorablePriceSpanSize: 8, // 价格设置span大小
      loadingCarrierAccountOptions: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.form.getFieldDecorator('keys', { initialValue: [0], preserve: true })
    this.fetchCardTypes()
  },
  methods: {
    onValuesChange(props, values) {
      if (values.is_has_voice_function !== undefined) {
        this.isHasVoiceFunction = values.is_has_voice_function
      }

      if (values.is_allow_retail !== undefined) {
        this.isAllowRetail = values.is_allow_retail
        this.isAllowSetRetailPrice = false
        this.favorablePriceSpanSize = 8

        this.form.setFieldsValue({
          retail_operator: undefined,
          retail_price: undefined
        })
      }

      if (values.retail_operator !== undefined) {
        this.isAllowSetRetailPrice = values.retail_operator === 'platform'
        this.favorablePriceSpanSize = this.isAllowSetRetailPrice ? 6 : 8
      }

      if (values.is_allow_self_subscribe !== undefined) {
        this.isAllowSelfSubscribe = values.is_allow_self_subscribe
      }

      if (values.user_business_type !== undefined) {
        this.isAllowRetail = false
        this.isAllowSetRetailPrice = false
        this.favorablePriceSpanSize = 8

        this.isAllowSelfSubscribe = false

        this.form.setFieldsValue({
          is_allow_retail: false,
          retail_operator: undefined,
          retail_price: undefined,
          is_allow_self_subscribe: false
        })
      }
    },

    checkRetailPrice(rule, value, callback, k) {
      const distributorPrice = this.form.getFieldValue(`base_product_favorable_prices[${k}].distributor_price`)
      if (value < distributorPrice) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('不能低于标准价!')
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    checkCycles(rule, value, callback, k) {
      if (!value) {
        // eslint-disable-next-line standard/no-callback-literal
        callback()
        return
      }
      const preCycles = this.form.getFieldValue(`base_product_favorable_prices[${k - 1}].cycles`)
      if (preCycles && preCycles >= value) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('应比上个周期数大!')
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    // 校验优惠价周期
    validateFavorablePriceCycles() {
      this.form.getFieldValue('keys').forEach((k) => {
        if (this.form.getFieldValue(`base_product_favorable_prices[${k}].cycles`)) {
          this.$nextTick(() => {
            this.form.validateFields([`base_product_favorable_prices[${k}].cycles`], { force: true })
          })
        }
      })
    },

    removeSetPriceItem(k) {
      const { form } = this
      // can use data-binding to get
      const keys = form.getFieldValue('keys')
      // We need at least one passenger
      if (keys.length === 1) {
        return
      }

      // can use data-binding to set
      form.setFieldsValue({
        keys: keys.filter(key => key !== k)
      })

      this.validateFavorablePriceCycles()
    },

    addSetPriceItem() {
      const { form } = this
      // can use data-binding to get
      const keys = form.getFieldValue('keys')
      const nextKeys = keys.concat(this.priceIndex++)
      // can use data-binding to set
      // important! notify form to detect changes
      form.setFieldsValue({
        keys: nextKeys
      })
    },

    changeCardType(value, index) {
      this.cardTypePriceRequired[index] = value.length !== 0

      if (value.length === 0) {
        this.$nextTick(() => {
          this.form.validateFields([`base_products_card_types[${index}].distributor_price`], { force: true })
          this.form.validateFields([`base_products_card_types[${index}].floor_price`], { force: true })
        })
      }
    },

    // 运营商种类变更，加载运营商账户和运营商状态
    handleCarrierTypeChange(carrierType) {
      this.loadingCarrierAccountOptions = true
      // 清空选项
      this.carrierAccountOptions = []
      // 清空选项选中的值
      this.form.setFieldsValue({
        carrier_account_ids: undefined
      })
      this.directionalShow = false

      if (carrierType) {
        findCarrierAccountOptions(carrierType).then((res) => {
          if (res.code === 0) {
            this.loadingCarrierAccountOptions = false
            this.carrierAccountOptions = res.data
          }
        })
      }
    },

    handleUserBusinessTypeChange(value) {
      this.userBusinessType = value
      this.isShowExcessRateItem = true
      this.isShowStopItem = false

      if (value === 'custom_month') {
        this.isShowStopItem = true

        this.capacityUnitOptions = [
          { name: 'MB', value: 'mb' },
          { name: 'GB', value: 'gb' },
          { name: '次', value: 'count' }
        ]
      } else if (value === 'custom_day') {
        // 用户业务模式是自定义天数，不显示设置超额资费一项
        this.isShowExcessRateItem = false
        this.capacityUnitOptions = [
          { name: 'MB', value: 'mb' },
          { name: 'GB', value: 'gb' }
        ]
        this.form.setFieldsValue({ capacity_unit: 'mb' })
        this.excessRateQuantityUnit = 'MB'
      } else if (value === 'traffic_share') {
        this.isShowStopItem = true

        // 用户业务模式是流量共享(单月), 套餐流量单位不显示"次"，表单套餐流量单位和超额计费单位改为MB
        this.capacityUnitOptions = [
          { name: 'MB', value: 'mb' },
          { name: 'GB', value: 'gb' }
        ]
        this.form.setFieldsValue({ capacity_unit: 'mb' })
        this.excessRateQuantityUnit = 'MB'
      } else if (value === 'traffic_share_package') {
        // 用户业务模式是流量共享(总池), 套餐流量单位不显示"次"，表单套餐流量单位和超额计费单位改为MB
        this.capacityUnitOptions = [
          { name: 'MB', value: 'mb' },
          { name: 'GB', value: 'gb' }
        ]
        this.form.setFieldsValue({ capacity_unit: 'gb' })
        this.excessRateQuantityUnit = 'GB'
      } else if (value === 'merchant_sales') {
        // 用户业务模式是空白卡，不显示设置超额资费一项
        this.isShowExcessRateItem = false

        // 用户业务模式是空白卡, 套餐流量单位不显示"次"，表单套餐流量单位和超额计费单位改为MB
        this.capacityUnitOptions = [
          { name: 'MB', value: 'mb' }
        ]
        this.form.setFieldsValue({ capacity_unit: 'mb' })
        this.excessRateQuantityUnit = 'MB'
      }
    },

    // 处理 容量单位 变更
    handleCapacityUnitChange(value) {
      if (value === 'count') {
        this.packageCapacityTypeRule = {
          type: 'integer',
          message: '请输入 1 - 10000000 之间的整数'
        }
        this.excessRateQuantityUnit = '次'
      } else {
        this.packageCapacityTypeRule = {
          type: 'number',
          message: '请输入 0.01 - 10000000 之间的数字'
        }
        this.excessRateQuantityUnit = 'MB'
      }

      // 如果已经输入了值，则触发校验
      if (this.form.getFieldValue('package_capacity')) {
        this.$nextTick(() => {
          this.form.validateFields(['package_capacity'], { force: true })
        })
      }

      // 如果显示超额流量一项且已经输入了值，则触发校验
      if (this.isShowExcessRateItem && this.form.getFieldValue('excess_rate_quantity')) {
        this.$nextTick(() => {
          this.form.validateFields(['excess_rate_quantity'], { force: true })
        })
      }
    },

    fetchCardTypes() {
      findCardTypeOptions().then(res => {
        if (res.code === 0) {
          this.cardTypes = res.data
          for (let i = 0; i <= this.cardTypes.length; i++) {
            this.cardTypePriceRequired.push(false)
          }
        }
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.submitting = true
      this.form.validateFields((err, values) => {
        if (!err) {
          const baseProductsCardType = []
          if (this.cardTypes.length > 0) {
            values.base_products_card_types.forEach((cardType) => {
              if (cardType.card_type_id && cardType.card_type_id[0]) {
                baseProductsCardType.push({
                  ...cardType,
                  card_type_id: cardType.card_type_id[0]
                })
              }
            })

            if (baseProductsCardType.length === 0) {
              this.$error({
                title: '提示',
                content: '请勾选卡片材质'
              })
              this.submitting = false
              return
            }
          } else {
            this.$error({
              title: '提示',
              content: '当前无卡片材质可勾选，请先维护卡片材质！'
            })
            this.submitting = false
            return
          }

          const data = {
            ...values,
            product_type: 'common',
            base_product_favorable_prices: values.base_product_favorable_prices.filter(key => key),
            base_products_card_types: baseProductsCardType
          }

          createBaseProduct(data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        } else {
          this.submitting = false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.item-title {
  color: @primary-color;
}
</style>
